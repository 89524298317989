<template>
    <b-form @submit.prevent>
        <b-row class="align-items-end">
            <b-col cols="12" :md="modal ? 12 : 3" class="mb-3 mb-md-0">
                <b-form-group
                    id="name"
                    class="row"
                    :label="$t('contact.name')"
                    label-for="name-input"
                    label-cols="12"
                    content-cols="12"
                >
                    <b-form-input id="name-input" v-model="model.name" :placeholder="$t('contact.placeholders.name')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col v-if="!modal" cols="12" md="3" class="text-center text-md-start">
                <b-button variant="primary" @click="save">
                    <i class="fas fa-save me-2"></i>{{ $t('contact.save') }}</b-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ContactForm",

    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            model: 'contact/item'
        })
    },

    methods: {
        save: function () {
            const action = this.model && this.model.m_id ? 'update' : 'create';

            return this.$store.dispatch(`contact/${action}`, this.model);
        },
    },

    destroyed() {
        this.$store.dispatch('contact/emptyItem');
    }
}
</script>

<style scoped>

</style>
